import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player/lazy";
import "../styles/homescreen.css";
import apple from "../assets/images/apple.png";
import android from "../assets/images/android.png";
import PlayIcon from "../assets/images/playIcon.svg";
// import vid from "../assets/images/fitflex_vid.mp4";
import { Link, Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { Button, Spin } from "antd";
import {
  addMobilePackage,
  isAuthenticated,
  getNumber,
  logUser,
  indonesiaMarketingLogs,
  logUserV2,
} from "../api/requests";
import { LoadingOutlined } from "@ant-design/icons";
import { useTranslation, Trans } from "react-i18next";
// import { ArrowRightOutlined } from "@ant-design/icons";
import WhatsappIcon from "../assets/images/whatsapp-icon.svg";
import rings from "../assets/images/rings.png";
import {
  Container,
  Button as FloatButton,
  lightColors,
  darkColors,
} from "react-floating-action-button";

const antIcon = (
  <LoadingOutlined
    style={{ fontSize: 45, color: "#FF1E1E", marginBottom: 20 }}
    spin
  />
);

const SubHeader = () => {
  const [nextVideo, setNextVideo] = useState(false);
  const [yesSubscription, setYesSubscription] = useState(false);
  const country = localStorage.getItem("country");
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [isFloatingButton, setIsFloatingButton] = useState(false);
  const [msg] = useSearchParams();
  const navigate = useNavigate();

  const encryptedMsisdn = JSON.parse(localStorage.getItem("encryptedMsisdn"));
  // const country = localStorage.getItem("country");

  console.log("message is :::", msg.get("user"));
  let whatsappParam = msg.get("user");

  let number = getNumber();
  const logWhatsappUser = () => {
    let user = {
      page: "link_to_whatsapp_btn",
      query: window.location.href,
    };
    logUser(user)
      .then((response) => {
        console.log("error is ", response);
        if (response.status === 200) {
          window.location.href =
            "https://api.whatsapp.com/send?phone=923000674949";
        }
      })
      .catch((error) => {
        console.log("error is ::::", error);
      });
  };
  const handleScroll = () => {
    // Calculate the position of the first div
    const firstDiv = document.querySelector(".layout1");
    if (firstDiv) {
      const firstDivPosition = firstDiv.getBoundingClientRect();

      // Check if the top of the first div is below the viewport
      const isFirstDivVisible = firstDivPosition.top >= 0;

      // Update the state variable
      setIsFloatingButton(!isFirstDivVisible);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const payload = {
    language_id: 7,
    country_id:
      country !== null
        ? country === "PK"
          ? 162
          : country === "IQ"
          ? 102
          : 100
        : "PK",
    operator:
      country !== null
        ? country === "PK"
          ? "jazz"
          : country === "IQ"
          ? "zain"
          : "telkomsel"
        : "jazz",
  };

  const route = (path) => {
    <Navigate to={path} />;
  };

  const token = isAuthenticated();
  console.log("TOKEN: ", token);

  const subCheck = (payments) => {
    let id = "";
    let iteration = 0;
    payments.forEach((val, index) => {
      if (val.activate === true || val.paid === true) {
        console.log("Here", val.package_id);
        iteration = iteration + 1;
        id = val.package_id;
      }
    });
    if (iteration > 0) {
      setYesSubscription(true);
    }
  };

  useEffect(() => {
    if (token !== false) {
      addMobilePackage(payload, token)
        .then((data) => {
          if (data.status === 200) {
            console.log("my data", data.data);
            subCheck(data.data);
            setLoading(false);
          }
        })
        .catch((err) => console.log(err));
    } else {
      setYesSubscription(false);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    let loggingBody = {
      page: "home_page",
      query: window.location.href,
    };

    logUserV2(loggingBody).then((response) => {
      if (response.status === "200") {
        console.log("iraq logging user v2 in home page :::", response);
      }
    });
  }, []);

  if (loading) {
    return (
      <div class="loader">
        <Spin
          className="spinner"
          indicator={antIcon}
          tip="Fetching Details..."
        />
      </div>
    );
  }

  return (
    <>
      {/* {isFloatingButton && (
        <Container styles={{ zIndex: "1", right: "2vw" }}>
          <FloatButton
            onClick={() => {
              if (whatsappParam && whatsappParam === "SMSuser") {
                logWhatsappUser();
              } else {
                window.location.href =
                  "https://api.whatsapp.com/send?phone=923000674949";
              }
            }}
            tooltip="Live Consultation with Experts"
            styles={{
              backgroundColor: darkColors.lighterRed,
              color: lightColors.white,
            }}
            icon="fab fa-whatsapp"
            iconStyles={{
              fontSize: "30px",
            }}
          />
        </Container>
      )} */}
      <div className="site-layout-content">
        <div className="indicator" />

        <div className="indicator2" />
        <div className="layout1">
          <div
            className="header-content"
            // style={{
            //   display: "flex",
            //   flexDirection: "column",
            //   marginTop: "100px",
            //   width: "60vw",
            // }}
          >
            <img
              src={rings}
              alt="rings"
              style={{ width: "5%", position: "absolute", left: 0 }}
              className="rings"
            />
            <h1 className="title" style={{ marginTop: "-3px" }}>
              {/* {t("workout_partner_title")} */}
              Your Gateway to a Healthier Lifestyle
            </h1>
            {/* <h2 className="title-secondary">
              Start Your Fitness Journey With HD Quality Content
            </h2> */}

            <h2 className="title-secondary">
              <Trans i18nKey={"workout_partner_text"} />
              {/* Chat with us for best workout and diet plans for your
              transformation journey designed by our Lifestyle Management
              Professionals. */}
              <br />
              <br />
              {/* <span style={{ color: "white" }}>{t("download_text")}</span> */}
            </h2>

            {/* <Link className="title-tertiary" to={"/plans"}>
              <p style={{ fontSize: "13px" }}>
                {" "}
                Choose A Subscription Plan That Fits You Best
              </p>
            </Link> */}
            <div className="btnDiv">
              <Button
                className="header-btn"
                onClick={() => {
                  // if (whatsappParam && whatsappParam === "SMSuser") {
                  //   logWhatsappUser();
                  // } else {
                  //   window.location.href =
                  //     "https://api.whatsapp.com/send?phone=923000674949";
                  // }
                  token === false
                    ? navigate("/login")
                    : encryptedMsisdn === null && country === "ZA"
                    ? (window.location.href =
                        "http://fitflexapp.com/sa_callback/")
                    : navigate("/plans");
                }}
              >
                {yesSubscription
                  ? `${t("current_plan")}`
                  : `${t("choose_plan")}`}
                {/* <img src={WhatsappIcon} alt="whatsappicon" />
                  Chat With Us */}
              </Button>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "30px",
                justifyContent: "space-evenly",
                width: "45%",
              }}
            >
              <a
                href="https://play.google.com/store/apps/details?id=fitflex.workout.fitness.weight.gym.fat.training"
                target="_blank"
              >
                <img
                  src={android}
                  style={{
                    width: 120,
                    // maxWidth: "100%",
                    height: "auto",
                    border: "1px solid grey",
                    borderRadius: "4px",
                  }}
                  className="subheader-download-links"
                  // className="small-div"

                  alt="appstore"
                />
              </a>
              <a
                href="https://apps.apple.com/us/app/fitflex/id1613514782"
                target="_blank"
              >
                <img
                  src={apple}
                  style={{
                    width: 120,
                    // maxWidth: "100%",
                    height: "auto",
                    border: "1px solid grey",
                    borderRadius: "4px",
                  }}
                  // className="small-div"
                  className="subheader-download-links"
                  alt="appstore"
                />
              </a>
            </div>
          </div>
          <video
            src={
              // nextVideo
              //   ? "https://fitflex.switchsolutions.com.pk/fit2.mp4"
              //   :
              // "https://qa.fitflexapp.com/fit1.mp4"
              "https://fitflexapp.com/web/fit1.mp4"
            }
            className="video-class"
            autoPlay={true}
            muted={true}
            controls={true}
            onEnded={() => setNextVideo(!nextVideo)}
            loop={true}
          />

          {/* </div> */}

          {/* <div className="icon-div">
            <div
              className="rounded"
              style={{
                border: !nextVideo
                  ? "2px solid rgba(255, 30, 30, 1)"
                  : // : "2px solid #fff",
                    "2px solid rgba(207, 207, 207,1)",
                borderTopColor: !nextVideo ? "#c4c4c4" : "#fff",
                marginRight: "20px",
              }}
              onClick={() => setNextVideo(false)}
            >
              <div className="dot" />
            </div>
            <div
              className="rounded"
              style={{
                border: nextVideo
                  ? "2px solid rgba(255, 30, 30, 1)"
                  : "2px solid #fff",
                borderTopColor: nextVideo ? "#c4c4c4" : "#fff",
              }}
              onClick={() => setNextVideo(true)}
            >
              <div className="dot" />
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default SubHeader;
